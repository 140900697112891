<template>
  <div class="group-detail-box animate__animated animate__fadeIn animate__faster">
    <commonContactItem></commonContactItem>
    <div class="relation-product" @click="handleOpenPop">关联产品</div>
    <van-pull-refresh v-model="refreshing" @refresh="handlePullDownRefresh">
      <van-list v-model="loading" :finished="finished" :offset="10" :immediate-check="false" :loading-text="loadingText"
        :finished-text="finishText" @load="handleReachBottom">
          <template v-if="list.length">
            <div class="animate__animated animate__fadeIn animate__faster" v-for="(item, index) in list" :key="index">
          <commonProductItem></commonProductItem>
        </div>
          </template>
      </van-list>
    </van-pull-refresh>
    <vistorLinkUsPop ref="vistorLinkUsPopRef"></vistorLinkUsPop>
  </div>
</template>
<script>
import commonContactItem from '@/components/commonContactItem.vue'
import commonProductItem from '../../components/commonProductItem.vue'
import vistorLinkUsPop from '../../components/vistorLinkUsPop.vue'
let arr = []
export default {
  name: '',
  props: {},
  components: { commonContactItem, commonProductItem,vistorLinkUsPop },
  data() {
    return {
      loading: false, //  控制数据的加载，false 代表没有正在刷新，true 代表正在刷新
      finished: false, // 是否已加载完成，加载完成后不再触发load事件
      refreshing: false, // 下拉状态是否处于加载中状态
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0, // 总条数
      pages: 0, // 总页数
      loadingText: '玩命加载中...',
      finishText: '没有更多了哦~',
      list: []
    }
  },
  methods: {
    handleOpenPop() {
      this.$refs.vistorLinkUsPopRef.open()
    },
    initData() {
      // 生成数据
      for (let i = 0; i < 40; i++) {
        arr.push({
          id: new Date().getTime(),
        })
      }
      this.getData()
    },

    async getData() {
      try {
        this.loading = true
        let { code, list, total, pages, pageNum } = await this.$getList(this.params, arr)
        console.log("list", list)
        console.log("pages", pages)
        console.log("pageNum", pageNum)
        this.list = this.params.pageNum == 1 ? [...list] : [...this.list, ...list];
        this.total = total
        this.pages = pages
        // 追加完成后关闭loading
        this.loading = false
        this.finishText = this.list.length ? '没有更多了' : '暂无更多数据'
      } catch (error) {
        if (this.params.pageNum > 0) {
          this.params.pageNum--
        }
        console.log(`output->error`, error)
      }
    },
    // 下拉刷新
    handlePullDownRefresh() {
      setTimeout(() => {
        this.params.pageNum = 1
        this.getData()
        this.refreshing = false
        this.finished = false // 重新加载
        if (!this.refreshing) {
          this.$toast.success('刷新成功')
        }
      }, 1000)
    },
    // 上拉加载
    handleReachBottom() {
      console.log('上拉加载')
      console.log(`output->this.params.pageNum`, this.params.pageNum)
      if (this.params.pageNum < this.pages) {
        this.params.pageNum++
        this.getData().finally(() => {
          this.loading = false
        })
      } else {
        this.finished = true
      }
    },
  },
  async mounted() {
    try {
      this.firstLoading = true
      await this.initData()
      setTimeout(() => {
        this.firstLoading = false
      }, 300)
    } catch (error) {
      setTimeout(() => {
        this.firstLoading = false
      }, 300)
    }
  },
}
</script>
<style scoped>
.group-detail-box {
  background-color: #fff;
  padding: 5px 12px 20px;
  box-sizing: border-box;
}

.relation-product {
  padding-top: 11px;
  padding-bottom: 10px;
  box-sizing: border-box;
  font-size: 15px;
  color: #262626;
  font-weight: 700;
}
</style>